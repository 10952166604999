import { graphql } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import { Helmet } from "react-helmet";
import ClientTestimonials from "../components/client-testimonials";
import CtaBanner from "../components/cta-banner";
import HeroBanner from "../components/hero";
import Layout from "../components/layout";
import TwoColumnContentWithBottomImage from "../components/two-column-content-with-bottom-image";
import TwoColumnImageContent from "../components/two-column-image-content";
import { checkPropertiesForNull } from "../utils";

const IndustryPostTemplate = ({ data: { post, site } }) => {
	const { title, industryPostFields } = post;

	const siteUrl = site.siteMetadata.siteUrl;
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Industries",
				item: {
					url: `${siteUrl}/industries`,
					id: `${siteUrl}/industries`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: `${post.title}`,
				item: {
					url: `${siteUrl}/industries/${post.slug}`,
					id: `${siteUrl}/industries/${post.slug}`,
				},
			},
		],
	};
	return (
		<Layout>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={post.seoFields?.title}
				description={post.seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/industries/${post.slug}`,
					title: `${post.seoFields?.opengraphTitle}`,
					description: `${post.seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${post.seoFields?.image?.node.sourceUrl}`,
							width: `${post.seoFields?.image?.node.mediaDetails.width}`,
							height: `${post.seoFields?.image?.node.mediaDetails.height}`,
							alt: `${post.seoFields?.image?.node.altText}`,
						},
					],
				}}
			/>

			<HeroBanner
				heading={industryPostFields?.industryBanner.industryBannerHeading}
				content={industryPostFields?.industryBanner.industryBannerContent}
				bgImage={
					industryPostFields?.industryBanner.industryBannerBackgroundImage?.node
						?.sourceUrl
				}
				showUnderline={false}
				textAlign={"center"}
				columns={8}
			/>
			{!checkPropertiesForNull(
				industryPostFields?.industryTwoColumnHeadingContent,
				["twoColumnHeading", "twoColumnContent"]
			) && (
				<TwoColumnContentWithBottomImage
					heading={
						industryPostFields?.industryTwoColumnHeadingContent.twoColumnHeading
					}
					content={
						industryPostFields?.industryTwoColumnHeadingContent.twoColumnContent
					}
					btnCta1={
						industryPostFields?.industryTwoColumnHeadingContent.twoColumnButton1
					}
					btnCta2={
						industryPostFields?.industryTwoColumnHeadingContent.twoColumnButton2
					}
					textColor="#252525"
				/>
			)}

			{!checkPropertiesForNull(
				industryPostFields?.industryCtaWithBackgroundImage,
				["industryCtaHeading", "industryCtaContent", "industryCtaButton"]
			) && (
				<CtaBanner
					heading={
						industryPostFields?.industryCtaWithBackgroundImage
							.industryCtaHeading
					}
					content={
						industryPostFields?.industryCtaWithBackgroundImage
							.industryCtaContent
					}
					btnCta1={
						industryPostFields?.industryCtaWithBackgroundImage.industryCtaButton
					}
					bgImage={
						industryPostFields?.industryCtaWithBackgroundImage
							.industryCtaBgImage?.node
					}
					textPosition="center"
				/>
			)}

			{!checkPropertiesForNull(
				industryPostFields?.industryTwoColumnCertification,
				["twoColumnHeading", "twoColumnContent"]
			) && (
				<TwoColumnImageContent
					heading={
						industryPostFields?.industryTwoColumnCertification.twoColumnHeading
					}
					content={
						industryPostFields?.industryTwoColumnCertification.twoColumnContent
					}
					tagline={
						industryPostFields?.industryTwoColumnCertification
							.twoColumnSubHeading
					}
					btnCta1={
						industryPostFields?.industryTwoColumnCertification.twoColumnButton1
					}
					btnCta2={
						industryPostFields?.industryTwoColumnCertification.twoColumnButton2
					}
					image={
						industryPostFields?.industryTwoColumnCertification.twoColumnImage
							.node
					}
					bgColor={
						industryPostFields?.industryTwoColumnCertification
							.twoColumnImageBackgroundColor
					}
					textColor={"#000"}
					flip={
						industryPostFields?.industryTwoColumnCertification
							.sectionImageLocation === "Left"
					}
				/>
			)}

			{!checkPropertiesForNull(industryPostFields?.industryTwoColumnEvents, [
				"twoColumnHeading",
				"twoColumnContent",
			]) && (
				<TwoColumnImageContent
					heading={industryPostFields?.industryTwoColumnEvents.twoColumnHeading}
					content={industryPostFields?.industryTwoColumnEvents.twoColumnContent}
					tagline={
						industryPostFields?.industryTwoColumnEvents.twoColumnSubHeading
					}
					btnCta1={industryPostFields?.industryTwoColumnEvents.twoColumnCta1}
					btnCta2={industryPostFields?.industryTwoColumnEvents.twoColumnCta2}
					image={
						industryPostFields?.industryTwoColumnEvents.twoColumnImage.node
					}
					mainBgColor={
						industryPostFields?.industryTwoColumnEvents.sectionBackgroundColor
					}
					textColor={"#fff"}
					bodyTextColor={"#fff"}
					borderColor={"#fff"}
					bgImage={
						industryPostFields?.industryTwoColumnEvents.sectionBackgroundImage
							?.node.sourceUrl
					}
					flip={
						industryPostFields?.industryTwoColumnEvents.sectionImageLocation ===
						"Left"
					}
				/>
			)}

			{!checkPropertiesForNull(industryPostFields?.industryTwoColumnServices, [
				"twoColumnHeading",
				"twoColumnContent",
			]) && (
				<TwoColumnImageContent
					heading={
						industryPostFields?.industryTwoColumnServices.twoColumnHeading
					}
					content={
						industryPostFields?.industryTwoColumnServices.twoColumnContent
					}
					tagline={
						industryPostFields?.industryTwoColumnServices.twoColumnSubHeading
					}
					btnCta1={
						industryPostFields?.industryTwoColumnServices.twoColumnButton1
					}
					btnCta2={
						industryPostFields?.industryTwoColumnServices.twoColumnButton2
					}
					image={
						industryPostFields?.industryTwoColumnServices.twoColumnImage.node
					}
					bgColor={
						industryPostFields?.industryTwoColumnServices
							.twoColumnImageBackgroundColor
					}
					textColor={"#000"}
					flip={
						industryPostFields?.industryTwoColumnServices
							.sectionImageLocation === "Left"
					}
				/>
			)}

			{!checkPropertiesForNull(
				industryPostFields?.industryTwoColumnOffsetting,
				["twoColumnHeading", "twoColumnContent"]
			) && (
				<TwoColumnImageContent
					heading={
						industryPostFields?.industryTwoColumnOffsetting.twoColumnHeading
					}
					content={
						industryPostFields?.industryTwoColumnOffsetting.twoColumnContent
					}
					tagline={
						industryPostFields?.industryTwoColumnOffsetting.twoColumnSubHeading
					}
					btnCta1={
						industryPostFields?.industryTwoColumnOffsetting.twoColumnButton1
					}
					btnCta2={
						industryPostFields?.industryTwoColumnOffsetting.twoColumnButton2
					}
					image={
						industryPostFields?.industryTwoColumnOffsetting.twoColumnImage.node
					}
					mainBgColor={
						industryPostFields?.industryTwoColumnOffsetting
							.sectionBackgroundColor
					}
					textColor={"#fff"}
					bodyTextColor={"#fff"}
					borderColor={"#fff"}
					flip={
						industryPostFields?.industryTwoColumnOffsetting
							.sectionImageLocation === "Left"
					}
				/>
			)}
			{industryPostFields?.industryTestimonials.selectTestimonials && (
				<ClientTestimonials
					testimonials={
						industryPostFields?.industryTestimonials.selectTestimonials.nodes
					}
				/>
			)}

			{!checkPropertiesForNull(industryPostFields?.industryTwoColumnCasestudy, [
				"twoColumnHeading",
				"twoColumnContent",
			]) && (
				<TwoColumnImageContent
					heading={
						industryPostFields?.industryTwoColumnCasestudy.twoColumnHeading
					}
					content={
						industryPostFields?.industryTwoColumnCasestudy.twoColumnContent
					}
					tagline={
						industryPostFields?.industryTwoColumnCasestudy.twoColumnTagline
					}
					btnCta1={
						industryPostFields?.industryTwoColumnCasestudy.twoColumnButton1
					}
					btnCta2={
						industryPostFields?.industryTwoColumnCasestudy.twoColumnButton2
					}
					image={
						industryPostFields?.industryTwoColumnCasestudy.twoColumnImage?.node
					}
					video={
						industryPostFields?.industryTwoColumnCasestudy.twoColumnVideo?.node
					}
					videoUrl={
						industryPostFields?.industryTwoColumnCasestudy.twoColumnVideoYoutube
					}
					mainBgColor={
						industryPostFields?.industryTwoColumnCasestudy.twoColumnBackground
					}
					textColor={"#000"}
					bodyTextColor={"#000"}
					flip={
						industryPostFields?.industryTwoColumnCasestudy
							.sectionImageLocation === "Left"
					}
				/>
			)}

			{!checkPropertiesForNull(
				industryPostFields?.ourTeamCtaWithBackgroundImage,
				["industryCtaHeading", "industryCtaContent"]
			) && (
				<CtaBanner
					heading={
						industryPostFields?.ourTeamCtaWithBackgroundImage.industryCtaHeading
					}
					content={
						industryPostFields?.ourTeamCtaWithBackgroundImage.industryCtaContent
					}
					btnCta1={
						industryPostFields?.ourTeamCtaWithBackgroundImage.industryCtaButton
					}
					bgImage={
						industryPostFields?.ourTeamCtaWithBackgroundImage.industryCtaBgImage
							?.node
					}
					textPosition="center"
				/>
			)}
		</Layout>
	);
};

export default IndustryPostTemplate;

export const pageQuery = graphql`
	query PostById($id: String!) {
		post: wpIndustry(id: { eq: $id }) {
			seoFields {
				title
				opengraphTitle
				opengraphDescription
				metaDescription
				localBusinessSchema
				image {
					node {
						altText
						sourceUrl
						mediaDetails {
							height
							width
						}
					}
				}
			}
			id
			slug
			title
			industryPostFields {
				industryBanner {
					industryBannerHeading
					industryBannerContent
					industryBannerBackgroundImage {
						node {
							altText
							sourceUrl
						}
					}
				}
				industryTwoColumnHeadingContent {
					twoColumnHeading
					twoColumnContent
				}
				industryCtaWithBackgroundImage {
					industryCtaHeading
					industryCtaContent
					industryCtaButton {
						title
						target
						url
					}
					industryCtaBgImage {
						node {
							altText
							sourceUrl
						}
					}
				}
				industryTwoColumnCertification {
					twoColumnHeading
					twoColumnSubHeading
					twoColumnImageBackgroundColor
					twoColumnContent
					sectionImageLocation
					sectionBackgroundColor
					sectionBackgroundImage {
						node {
							altText
							sourceUrl
						}
					}
					twoColumnButton1 {
						title
						target
						url
					}
					twoColumnButton2 {
						title
						target
						url
					}
					twoColumnImage {
						node {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: WEBP
										quality: 80
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}
				industryTwoColumnEvents {
					twoColumnHeading
					twoColumnSubHeading
					twoColumnImageBackgroundColor
					twoColumnContent
					sectionImageLocation
					sectionBackgroundColor
					sectionBackgroundImage {
						node {
							altText
							sourceUrl
						}
					}
					twoColumnButton1 {
						title
						target
						url
					}
					twoColumnButton2 {
						title
						target
						url
					}
					twoColumnImage {
						node {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: WEBP
										quality: 80
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}
				industryTwoColumnServices {
					twoColumnHeading
					twoColumnSubHeading
					twoColumnImageBackgroundColor
					sectionImageLocation
					sectionBackgroundColor
					sectionBackgroundImage {
						node {
							altText
							sourceUrl
						}
					}
					twoColumnButton1 {
						title
						target
						url
					}
					twoColumnButton2 {
						title
						target
						url
					}
					twoColumnContent
					twoColumnImage {
						node {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: WEBP
										quality: 80
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}
				industryTwoColumnOffsetting {
					twoColumnHeading
					twoColumnContent
					twoColumnSubHeading
					twoColumnImageBackgroundColor
					sectionImageLocation
					sectionBackgroundColor
					sectionBackgroundImage {
						node {
							altText
							sourceUrl
						}
					}
					twoColumnButton1 {
						title
						target
						url
					}
					twoColumnButton2 {
						title
						target
						url
					}
					twoColumnImage {
						node {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: WEBP
										quality: 80
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}
				industryTwoColumnCasestudy {
					twoColumnHeading
					twoColumnSubHeading
					twoColumnContent
					sectionBackgroundColor
					sectionImageLocation
					sectionBackgroundImage {
						node {
							altText
							sourceUrl
						}
					}
					twoColumnButton1 {
						title
						target
						url
					}
					twoColumnButton2 {
						title
						target
						url
					}
					twoColumnImageBackgroundColor
					twoColumnImage {
						node {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: WEBP
										quality: 80
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					twoColumnVideoYoutube
					twoColumnVideo {
						node {
							altText
							mediaItemUrl
						}
					}
				}
				industryTestimonials {
					selectTestimonials {
						nodes {
							... on WpReview {
								title
								date(formatString: "DD MMMM, YYYY")
								reviewPostFields {
									reviewAuthor
									reviewAuthorProfilePicture {
										node {
											altText
											sourceUrl
											localFile {
												childImageSharp {
													gatsbyImageData(
														formats: WEBP
														quality: 80
														transformOptions: { cropFocus: CENTER, fit: COVER }
														layout: CONSTRAINED
														placeholder: BLURRED
														width: 56
													)
												}
											}
										}
									}
									reviewQuote
									reviewStarSelect
									reviewAuthorCompanyPicture {
										node {
											altText
											sourceUrl
											localFile {
												childImageSharp {
													gatsbyImageData(
														formats: WEBP
														quality: 80
														transformOptions: { cropFocus: CENTER, fit: COVER }
														layout: CONSTRAINED
														placeholder: BLURRED
														width: 80
													)
												}
											}
										}
									}
									reviewAuthorDesignation
								}
								terms {
									nodes {
										taxonomyName
										name
									}
								}
							}
						}
					}
				}
				ourTeamCtaWithBackgroundImage {
					industryCtaHeading
					industryCtaContent
					industryCtaButton {
						target
						title
						url
					}
					industryCtaBgImage {
						node {
							altText
							sourceUrl
						}
					}
				}
				industryCardInfo {
					industryCaseStudyLink {
						nodes {
							slug
						}
					}
					industryIcon {
						node {
							altText
							sourceUrl
						}
					}
				}
			}
		}
		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
